@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import "variables";
@import "bootstrap";

/*pages*/

:root {}

html,
body {
  max-width: 100% !important;
  overflow-x: hidden !important;
  font-family: 'Poppins', sans-serif !important;
}

::selection {
  color: white !important;
  background: $black !important;
}
.css-2zodua-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: black !important;
  border-color: black !important;
  background-color: white !important;
}
.excelIcon{
  width: 18px !important;
}

.scrollable-content {
  padding-top: 50px; 
  max-height: calc(100vh - 50px); 
  overflow-y: scroll; 
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.scrollable-content::-webkit-scrollbar {
  display: none; 
}


// Mob view
@media only screen and (max-width: 600px) {
  
}