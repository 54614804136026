@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300&display=swap');

.fontsizing {
  font-size: medium;
  font-family: 'Kanit', sans-serif;
}

.otp-input-container {
  display: flex;
  justify-content: space-evenly;
}

.otp-input {
  font-size: 24px;
  letter-spacing: 20px;
  text-align: end;
  outline: none;
  width: 40%;
  caret-color: transparent;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  // margin-left: 0;
  display: flex;
  justify-content: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.scrollable-content {
  padding-top: 50px; /* Ensure content starts below the fixed title */
  max-height: calc(100vh - 50px); /* Adjust the height to fit the remaining space */
  overflow-y: scroll; /* Enable vertical scrolling */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
}
.scrollable-content::-webkit-scrollbar {
  display: none; 
}
