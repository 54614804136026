$body-bg : #ffffff;
$primary : #c0045f;
$secondary : #eba832;
$success : #18bf05;
$warning : #EEC808;
$info : #0E70F1;
$danger : #ef3d18;
$gray : #333333;
$light-gray : #dcdcdc;
$black : #000000;

:root {
    --side-nav-bar-width: 325px;
    --side-nav-bar-width-ne: -325px;
    --nav-header-height: 80px;
}