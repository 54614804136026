// .MuiTelInput-TextField {
//   border: none !important;
// }
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300&display=swap");

.fontsizing1 {
  font-size: 10px;
  font-family: "Kanit", sans-serif;
}
// .css-z7uhs0-MuiStepConnector-line{
//   border-color: #0070C1 !important;
// }
.scrollable-content {
  padding-top: 50px; 
  max-height: calc(100vh - 50px); 
  overflow-y: scroll; 
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.scrollable-content::-webkit-scrollbar {
  display: none; 
}
